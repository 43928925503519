<template>
  <k-layout>
    <header class="tw-bg-white tw-px-4">
      <div class="tw-relative tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg tw-py-12 md:tw-py-20">
        <div
          class="tw-absolute tw-bottom-0 tw-right-0 tw-max-h-96 tw-max-w-xs tw-h-full tw-w-full tw-hidden lg:tw-block tw-pointer-events-none"
          style="margin-bottom: -50px"
        >
          <img src="./assets/hero.svg" class="tw-w-full tw-h-full tw-object-contain" />
        </div>

        <k-product-title :title="$t('work_comparison.title')" />

        <h2 class="tw-mt-8 tw-heading-2" v-html="$t('work_comparison.subtitle')" />

        <p class="tw-mt-4 tw-text-gray-500 tw-text-xl" v-html="$t('work_comparison.descriptions.main')" />
      </div>
    </header>

    <main class="tw-mt-8 tw-px-4 tw-mb-24">
      <div class="tw-mx-auto tw-w-full tw-max-w-xl lg:tw-max-w-screen-lg">
        <div class="tw-flex tw-flex-col lg:tw-flex-row tw-space-y-8 lg:tw-space-y-0 lg:tw-space-x-8">
          <div class="tw-w-full tw-space-y-8">
            <section class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-py-8">
              <div class="tw-overflow-x-auto">
                <table class="tw-w-full tw-divide-y tw-divide-gray-200 tw-border-collapse" style="min-width: 540px">
                  <thead>
                  <tr>
                    <th>
                      <!-- Empty -->
                    </th>
                    <th
                      scope="col"
                      class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider"
                    >
                      <abbr :title="$t('work_comparison.permanent_employment')">TPP</abbr>
                    </th>
                    <th
                      scope="col"
                      class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider"
                    >
                      <abbr :title="$t('work_comparison.self_employed_person')">SZČO</abbr>
                    </th>
                    <th
                      scope="col"
                      class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider"
                    >
                      <abbr :title="$t('work_comparison.limited_liability_company')">S.R.O.</abbr>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="tw-text-gray-900 tw-font-bold tw-px-6 tw-py-4 tw-whitespace-nowrap">
                      {{ $t('work_comparison.subheadings.accounting') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <div class="tw-w-4 tw-bg-gray-300 tw-h-px tw-mx-auto" aria-hidden="true" />
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      {{ $t('work_comparison.simple') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      {{ $t('work_comparison.double') }}
                    </td>
                  </tr>

                  <tr class="tw-bg-gray-100">
                    <td class="tw-text-gray-900 tw-font-bold tw-px-6 tw-py-4 tw-whitespace-nowrap">
                      {{ $t('work_comparison.subheadings.establishment') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <div class="tw-w-4 tw-bg-gray-300 tw-h-px tw-mx-auto" aria-hidden="true" />
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black text-lowercase">
                      {{ $t('global.from') }} <span class="tw-font-bold">0€</span> {{ $t('global.to') }}
                      <span class="tw-font-bold">15€</span>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black text-lowercase">{{ $t('global.from') }} <span
                      class="tw-font-bold"
                    >150€</span></td>
                  </tr>

                  <tr>
                    <td class="tw-text-gray-900 tw-font-bold tw-px-6 tw-py-4 tw-whitespace-nowrap">
                      {{ $t('work_comparison.subheadings.taxes') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <span class="tw-font-bold">19%</span>
                      <div class="tw-relative tw-w-full tw-max-w-sm tw-py-1">
                        <div class="tw-absolute tw-inset-0 tw-flex tw-items-center">
                          <div class="tw-w-full tw-border-t tw-border-gray-300" />
                        </div>
                        <div class="tw-relative tw-flex tw-justify-center tw-text-xs">
                          <span class="tw-px-2 tw-bg-white tw-text-gray-600">36 256,37€</span>
                        </div>
                      </div>
                      <span class="tw-font-bold">25%</span>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
                        <span class="tw-font-bold">15%</span>
                        <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.75)">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="tw-flex tw-items-center">
                              <k-icon
                                name="info"
                                class="tw-text-gray-600"
                                size="20"
                              />
                            </span>
                          </template>
                          <p>
                            {{ $t('work_comparison.descriptions.taxes_follows_law') }}
                          </p>
                        </v-tooltip>
                      </div>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
                        <div>
                          {{ $t('work_comparison.income_tax') }} <span class="tw-font-bold">15%</span>
                          <br />
                          {{ $t('work_comparison.dividend_tax') }} <span class="tw-font-bold">7%</span>
                        </div>
                        <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.75)">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="tw-flex tw-items-center">
                              <k-icon
                                name="info"
                                class="tw-text-gray-600"
                                size="20"
                              />
                            </span>
                          </template>
                          <p>
                            {{ $t('work_comparison.descriptions.taxes_follows_law') }}
                          </p>
                        </v-tooltip>
                      </div>
                    </td>
                  </tr>

                  <tr class="tw-bg-gray-100">
                    <td class="tw-text-gray-900 tw-font-bold tw-px-6 tw-py-4 tw-whitespace-nowrap">
                      {{ $t('work_comparison.subheadings.non_taxable_part') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-black">
                      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
                        <span><span class="tw-font-bold">4 511,43 €</span> / {{ $t('global.time.year') }}</span>
                        <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.75)">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="tw-flex tw-items-center">
                              <k-icon
                                name="info"
                                class="tw-text-gray-600"
                                size="20"
                              />
                            </span>
                          </template>
                          <p>
                            {{ $t('work_comparison.descriptions.annual_amount_non_taxable_part') }}
                          </p>
                        </v-tooltip>
                      </div>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-black">
                      <div class="tw-flex tw-items-center tw-justify-center tw-space-x-2">
                        <span><span class="tw-font-bold">4 511,43 €</span> / {{ $t('global.time.year') }}</span>
                        <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.75)">
                          <template v-slot:activator="{ on }">
                            <span v-on="on" class="tw-flex tw-items-center">
                              <k-icon
                                name="info"
                                class="tw-text-gray-600"
                                size="20"
                              />
                            </span>
                          </template>
                          <p>
                            {{ $t('work_comparison.descriptions.annual_amount_non_taxable_part') }}
                          </p>
                        </v-tooltip>
                      </div>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <div class="tw-w-4 tw-bg-gray-300 tw-h-px tw-mx-auto" aria-hidden="true" />
                    </td>
                  </tr>

                  <tr>
                    <td class="tw-text-gray-900 tw-font-bold tw-px-6 tw-py-4 tw-whitespace-nowrap">
                      {{ $t('work_comparison.subheadings.levies') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <span><abbr :title="$t('calculator.social_insurance')">SP</abbr> – <span class="tw-font-bold">9,4%</span></span>
                      <br />
                      <span><abbr :title="$t('calculator.health_insurance')">ZP</abbr> – <span class="tw-font-bold">4%</span></span>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.75)">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="tw-inline-flex tw-items-center">
                            <k-icon name="info" class="tw-text-gray-600" size="20" />
                          </span>
                        </template>
                        <p>
                          {{ $t('work_comparison.descriptions.social_insurance_not_paid') }}
                        </p>
                      </v-tooltip>
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      <v-tooltip top max-width="600" color="rgba(0, 0, 0, 0.75)">
                        <template v-slot:activator="{ on }">
                          <span v-on="on" class="tw-inline-flex tw-items-center">
                            <k-icon name="info" class="tw-text-gray-600" size="20" />
                          </span>
                        </template>
                        <p>
                          {{ $t('work_comparison.descriptions.manager_one_person') }}
                        </p>
                      </v-tooltip>
                    </td>
                  </tr>

                  <tr class="tw-bg-gray-100">
                    <td class="tw-text-gray-900 tw-font-bold tw-px-6 tw-py-4 tw-whitespace-nowrap">
                      {{ $t('work_comparison.other') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      {{ $t('work_comparison.holiday') }},
                      <abbr :title="$t('work_comparison.temporary_incapacity_for_work')">
                        {{ $t('work_comparison.sick_leave') }}
                      </abbr>,<br />
                      {{ $t('work_comparison.retirement_savings') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      {{ $t('work_comparison.low_contributions_low_pension') }}
                    </td>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-black">
                      {{ $t('work_comparison.low_contributions_low_pension') }}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-py-8">
              <div class="tw-overflow-x-auto">
                <table class="w-w-full tw-divide-y tw-divide-gray-200 tw-border-collapse" style="min-width: 540px">
                  <thead>
                  <tr>
                    <th>
                      <!-- Empty -->
                    </th>
                    <th
                      scope="col"
                      class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider"
                      style="width: 40%"
                    >
                      {{ $t('work_comparison.benefits') }}
                    </th>
                    <th
                      scope="col"
                      class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider"
                      style="width: 40%"
                    >
                      {{ $t('work_comparison.disadvantages') }}
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider">
                      <abbr :title="$t('work_comparison.permanent_employment')">TPP</abbr>
                    </td>

                    <td class="tw-px-6 tw-py-3">
                      <ul class="marker-positive">
                        <li>{{ $t('work_comparison.regular_income') }}</li>
                        <li>{{ $t('work_comparison.financial_benefits') }}</li>
                        <li>{{ $t('work_comparison.get_loan') }}</li>
                      </ul>
                    </td>

                    <td class="tw-px-6 tw-py-3">
                      <ul class="marker-negative">
                        <li>{{ $t('work_comparison.lower_salary') }}</li>
                        <li>{{ $t('work_comparison.working_hours') }}</li>
                        <li>{{ $t('work_comparison.notice_period') }}</li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider">
                      <abbr :title="$t('work_comparison.self_employed_person')">SZČO</abbr>
                    </td>

                    <td class="tw-px-6 tw-py-3">
                      <ul class="marker-positive">
                        <li>{{ $t('work_comparison.fast_establishment_process') }}</li>
                        <li>{{ $t('work_comparison.possible_flat_rate_expenses') }}</li>
                        <li>{{ $t('work_comparison.social_contributions_exemption') }}</li>
                      </ul>
                    </td>

                    <td class="tw-px-6 tw-py-3">
                      <ul class="marker-negative">
                        <li>{{ $t('work_comparison.unlimited_liability') }}</li>
                        <li>{{ $t('work_comparison.health_social_contributions_amount') }}</li>
                        <li>{{ $t('work_comparison.less_credible_image') }}</li>
                      </ul>
                    </td>
                  </tr>

                  <tr>
                    <td class="tw-px-6 tw-py-3 tw-text-center tw-text-xl tw-font-bold tw-black tw-uppercase tw-tracking-wider">
                      <abbr :title="$t('work_comparison.limited_liability_company')">S.R.O.</abbr>
                    </td>

                    <td class="tw-px-6 tw-py-3">
                      <ul class="marker-positive">
                        <li>{{ $t('work_comparison.limited_liability') }}</li>
                        <li>
                          {{ $t('work_comparison.credible_image') }}
                        </li>
                        <li>{{ $t('work_comparison.equal_income_tax') }}</li>
                      </ul>
                    </td>

                    <td class="tw-px-6 tw-py-3">
                      <ul class="marker-negative">
                        <li>{{ $t('work_comparison.expensive_founding_process') }}</li>
                        <li>{{ $t('work_comparison.demanding_administration') }}</li>
                        <li>{{ $t('work_comparison.termination_process') }}</li>
                      </ul>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-py-8">
                            <span class="tw-text-xl tw-font-bold tw-inline-block">
                                {{ $t('work_comparison.graph_net_income') }}
                                <abbr :title="$t('work_comparison.permanent_employment')">TPP</abbr>, <abbr :title="$t('work_comparison.self_employed_person')">SZČO</abbr> {{ $t('global.and')
                              }}
                                <abbr :title="$t('work_comparison.limited_liability_company')">S.R.O.</abbr>
                            </span>

              <div class="tw-mt-8 tw-overflow-x-auto">
                <div style="min-width: 540px">
                  <canvas id="calculator-chart"></canvas>
                </div>
              </div>

              <div class="tw-flex tw-flex-col lg:tw-flex-row lg:tw-items-center lg:tw-space-x-6 lg:tw-justify-center">
                <div
                  class="tw-space-x-2 tw-flex tw-items-center"
                  v-for="dataset in chartData.data.datasets"
                  :key="dataset.label"
                >
                  <div
                    class="tw-w-2 tw-h-2 tw-rounded-full"
                    :style="`background-color: ${dataset.pointBackgroundColor}`"
                  ></div>
                  <span>{{ dataset.label }}</span>
                </div>
              </div>
            </section>
          </div>

          <!--
          <div class="tw-hidden lg:tw-block tw-w-full lg:tw-max-w-xs">
              <aside class="tw-bg-white tw-rounded-sm tw-p-4 lg:tw-p-8"></aside>
          </div>
          -->
        </div>
      </div>
    </main>
  </k-layout>
</template>

<script>
import wageCalculator from '../addons/wage_calculator'
import Chart from 'chart.js'

export default {
  created() {
    this.compute()

    Chart.defaults.global.defaultFontFamily = '"soleil", sans-serif'
  },
  data() {
    return {
      salaryObjects: []
    }
  },
  methods: {
    compute() {
      let bruttoSalary = 739.645
      for (let multiplier = 0; multiplier <= 24; multiplier++) {
        let salaryObject = {
          employeeObject: {
            type: 'employee',
            bruttoSalary: bruttoSalary + (multiplier * 200) / 1.352
          },
          contractorObject: {
            type: 'contractor',
            bruttoSalary: bruttoSalary + (multiplier * 200) / 1.352
          },
          longTermContractorObject: {
            type: 'long-term-contractor',
            bruttoSalary: bruttoSalary + (multiplier * 200) / 1.352
          },
          companyObject: {
            type: 'company',
            bruttoSalary: bruttoSalary + (multiplier * 200) / 1.352
          }
        }
        Object.values(wageCalculator.employeeCounter).forEach(rule => {
          if (rule.condition(salaryObject.employeeObject)) {
            rule.modifier(salaryObject.employeeObject)
          }
        })
        Object.values(wageCalculator.contractorCounter).forEach(rule => {
          if (rule.condition(salaryObject.contractorObject)) {
            rule.modifier(salaryObject.contractorObject)
          }
        })
        Object.values(wageCalculator.longTermContractorCounter).forEach(rule => {
          if (rule.condition(salaryObject.longTermContractorObject)) {
            rule.modifier(salaryObject.longTermContractorObject)
          }
        })
        Object.values(wageCalculator.companyCounter).forEach(rule => {
          if (rule.condition(salaryObject.companyObject)) {
            rule.modifier(salaryObject.companyObject)
          }
        })
        this.salaryObjects.push(salaryObject)
      }
    },
    createChart(chartId, chartData) {
      new Chart(document.getElementById(chartId), {
        type: chartData.type,
        data: chartData.data,
        options: chartData.options
      })
    },
    displayChart() {
      this.createChart('calculator-chart', this.chartData)
    }
  },
  watch: {
    chartData: function () {
      this.displayChart()
    }
  },
  computed: {
    labels: function () {
      let labels = this.salaryObjects
        .map(e => e.employeeObject)
        .map(e => '' + (e.bruttoSalary / 1000).toFixed(2) + 'k€ (' + (e.grossSalary / 12000).toFixed(2) + 'k€)')
      return labels
    },
    employeeSalaryObjects: function () {
      return this.salaryObjects.map(e => e.employeeObject).map(e => ({
        gross: (e.grossSalary / 12).toFixed(2),
        netto: e.nettoSalary.toFixed(2)
      }))
    },
    shortContractorSalaryObjects: function () {
      return this.salaryObjects.map(e => e.contractorObject).map(e => ({
        gross: (e.grossIncome / 12).toFixed(2),
        netto: e.nettoIncome.toFixed(2)
      }))
    },
    longContractorSalaryObjects: function () {
      return this.salaryObjects
        .map(e => e.longTermContractorObject)
        .map(e => ({ gross: (e.grossIncome / 12).toFixed(2), netto: e.nettoIncome.toFixed(2) }))
    },
    companySalaryObjects: function () {
      return this.salaryObjects.map(e => e.companyObject).map(e => ({
        gross: (e.grossIncome / 12).toFixed(2),
        netto: e.nettoIncome.toFixed(2)
      }))
    },
    chartData: function () {
      const self = this

      return {
        type: 'line',
        data: {
          labels: this.labels,
          datasets: [
            {
              label: this.$i18n.t('work_comparison.permanent_employment'),
              data: this.employeeSalaryObjects.map(e => (parseFloat(e.netto) / 12).toFixed(2)),
              backgroundColor: 'rgba(102, 186, 237, 0)',
              borderColor: '#66baed',
              pointBackgroundColor: '#66baed',
              borderWidth: 2
            },
            {
              label: this.$i18n.t('calculator.trade_first_year'),
              data: this.shortContractorSalaryObjects.map(e => (parseFloat(e.netto) / 12).toFixed(2)),
              backgroundColor: 'rgba(247, 79, 79, 0)',
              pointBackgroundColor: '#f74f4f',
              borderColor: '#f74f4f',
              borderWidth: 2
            },
            {
              label: this.$i18n.t('work_comparison.trade_after_first_year'),
              data: this.longContractorSalaryObjects.map(e => (parseFloat(e.netto) / 12).toFixed(2)),
              backgroundColor: 'rgba(104, 188, 113, 0)',
              pointBackgroundColor: '#68bc71',
              borderColor: '#68bc71',
              borderWidth: 2
            },
            {
              label: this.$i18n.t('work_comparison.company'),
              data: this.companySalaryObjects.map(e => (parseFloat(e.netto) / 12).toFixed(2)),
              backgroundColor: 'rgba(102, 107, 237, 0)',
              pointBackgroundColor: '#666bed',
              borderColor: '#666bed',
              borderWidth: 2
            }
          ]
        },
        options: {
          title: {
            display: false
          },
          legend: {
            display: false
          },
          tooltips: {
            enabled: window.innerWidth >= 1024,
            mode: 'index',
            intersect: false,
            titleFontSize: 15,
            titleMarginBottom: 8,
            bodyFontSize: 12,
            bodySpacing: 6,
            callbacks: {
              label: function (tooltipItem, data) {
                return (
                  data.datasets[tooltipItem.datasetIndex].label +
                  ': ' +
                  tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') +
                  '€'
                )
              },
              title: function (tooltipItem) {
                return self.$i18n.t('work_comparison.chart_popup_title') + ': ' + tooltipItem[0].label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
              },
              footer: function () {
                return self.$i18n.t('work_comparison.chart_popup_more_info')
              }
            }
          },
          hover: {
            mode: 'nearest',
            intersect: true
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  labelString: this.$i18n.t('calculator.net_monthly_income'),
                  fontColor: '#222',
                  fontSize: 14,
                  fontStyle: 'normal'
                },
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value) {
                    value = (value / 1000).toFixed(1)
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + 'k€'
                  }
                }
              }
            ],
            xAxes: [
              {
                scaleLabel: {
                  display: true,
                  padding: 16,
                  labelString: 'Hrubá mzda (Fakturovaná suma)',
                  fontColor: '#222',
                  fontSize: 14,
                  fontStyle: 'normal'
                },
                ticks: {
                  // Include a dollar sign in the ticks
                  callback: function (value, index) {
                    if (index % 2) return ''
                    else return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
                  }
                }
              }
            ]
          }
        }
      }
    }
  }
}
</script>

<style scoped>
.marker-positive ::marker {
  color: #0ea66d;
  content: "\2713";
}

.marker-negative ::marker {
  color: #d14743;
  content: "\2717";
}

.marker-positive li,
.marker-negative li {
  padding-left: 0.5em;
}
</style>
